.btn {
  position: relative;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #2887e0;
  color: #2887e0;
  margin: 0;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  transition: all 0.1s ease-out;
  font-family: inherit;
  line-height: 1.1;
}
.outer:hover .btn,
.btn:hover {
  background: #2887e0;
  color: #fff;
}
.outer:active .btn,
.btn:active {
  background: #1c73c4;
  border-color: #1c73c4;
  color: #fff;
}
.btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.outer {
  cursor: pointer;
}
.sm {
  padding: 6px 10px;
  font-size: 12px;
}
.md {
  padding: 8px 23px;
  font-size: 14px;
}
.lg {
  padding: 14px 50px;
  font-size: 16px;
}
.only_icon {
  padding: 0;
  font-size: 0;
  line-height: 0;
}
.only_icon.sm {
  height: 29px;
  width: 29px;
  line-height: 27px;
}
.only_icon.md {
  height: 35px;
  width: 35px;
  line-height: 33px;
}
.only_icon.lg {
  height: 49px;
  width: 49px;
  line-height: 47px;
}
.block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.bold {
  font-weight: bold;
}
.filled {
  background: #1c73c4;
  border-color: #1c73c4;
  color: #fff;
}
.filled:active {
  background: #1c73c4;
  border-color: #1c73c4;
}
.ghost {
  border-color: #adadad;
  color: #717171;
}
.outer:hover .ghost,
.ghost:hover,
.outer:active .ghost,
.ghost:active {
  border-color: #2887e0;
}
.outer:hover .ghost .icon,
.ghost:hover .icon,
.outer:active .ghost .icon,
.ghost:active .icon {
  fill: #fff;
  stroke: #fff;
}
.ghost .icon {
  fill: #717171;
  stroke: #717171;
}
.disabled,
.outer:hover .disabled,
.disabled:hover,
.disabled:active {
  background: #717171;
  border-color: #717171;
  cursor: not-allowed;
  color: #fff;
}
.text {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}
.has_icon {
  margin-left: 9px;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  fill: #1c73c4;
  stroke: #1c73c4;
  stroke-width: 0px;
}
.btn:hover .icon,
.btn:active .icon {
  fill: #fff;
  stroke: #fff;
}
.loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: url("../../../styles/img/spin.svg");
}
.sm.loading:after {
  margin-top: -8px;
  margin-left: -8px;
  width: 16px;
  height: 16px;
}
.md.loading:after {
  margin-top: -10px;
  margin-left: -10px;
  width: 20px;
  height: 20px;
}
.lg.loading:after {
  margin-top: -15px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
}
