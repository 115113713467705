.btn
  position: relative
  //background: transparent
  background: $white
  border-radius: 4px
  border: 1px solid $red
  color: $red
  margin: 0
  cursor: pointer
  outline: 0
  display: inline-block
  vertical-align: middle
  text-align: center
  transition: all .1s ease-out
  font-family: inherit
  line-height: 1.1
  -webkit-appearance: none

  &::-moz-focus-inner
     border: 0;
     padding: 0;

.outer
  cursor: pointer

.sm
  padding: 6px 10px
  font-size: 12px

.md
  padding: 8px 23px
  font-size: 14px

.lg
  padding: 14px 50px
  font-size: 16px

.only_icon

  padding: 0
  //font-size: 0
  //line-height: 0
  &.sm
    height: 30px
    width: 30px
    line-height: 28px
  &.md
    height: 35px
    width: 35px
    line-height: 33px
  &.lg
    height: 49px
    width: 49px
    line-height: 47px

.block
  display: block
  width: 100%
  padding-left: 0
  padding-right: 0

.bold
  font-weight: bold

.filled
  background: $dark-red
  border-color: $dark-red
  color: #fff

  //&:active
  //  background: $dark-red
  //  border-color: $dark-red

  //.icon
  //  &:before
  //    opacity: 0
  //
  //  &:after
  //    opacity: 1

.ghost
  border-color: $grey500
  color: $grey700
  /.outer:hover &, &:hover
    border-color: $red

.disabled
  &
  /.outer:hover &,
  &:hover
  //&:active
    background: $grey700
    border-color: $grey700
    cursor: not-allowed
    color: #fff

.text
  line-height: 1.2
  display inline-block
  vertical-align: middle

.has_icon
  margin-left: 9px

.icon
  display inline-block
  vertical-align: middle
  position: relative
  fill: $white
  stroke: $white
  stroke-width: 0px

.loading
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.3)

  &:after
    content: ''
    display: block
    position: absolute
    left: 50%
    top: 50%
    background: url(../../../styles/img/spin.svg)

    loader-sizes = '.sm' 16px, '.md' 20px, '.lg' 30px

    for loader-size in loader-sizes
      class = loader-size[0]
      size = loader-size[1]
      half-size = -(size / 2)

      /{class} &
        margin-top: half-size
        margin-left: half-size
        width: size
        height: size
