.btn
  position: relative
  //background: transparent
  background: #fff
  border-radius: 4px
  border: 1px solid $red
  color: $red
  margin: 0
  cursor: pointer
  outline: 0
  display: inline-block
  vertical-align: middle
  text-align: center
  transition: all .1s ease-out
  font-family: inherit
  line-height: 1.1

  /.outer:hover &, &:hover
    background: $red
    color: #fff

  /.outer:active &, &:active
    background: $dark-red
    border-color: $dark-red
    color: #fff

  &::-moz-focus-inner
     border: 0
     padding: 0

.outer
  cursor: pointer

.sm
  padding: 6px 10px
  font-size: 12px

.md
  padding: 8px 23px
  font-size: 14px

.lg
  padding: 14px 50px
  font-size: 16px

.only_icon
  padding: 0
  font-size: 0
  line-height: 0
  &.sm
    height: 29px
    width: 29px
    line-height: 27px
  &.md
    height: 35px
    width: 35px
    line-height: 33px
  &.lg
    height: 49px
    width: 49px
    line-height: 47px

.block
  display: block
  width: 100%
  padding-left: 0
  padding-right: 0

.bold
  font-weight: bold

.filled
  background: $dark-red
  border-color: $dark-red
  color: #fff

  &:active
    background: $dark-red
    border-color: $dark-red

.ghost
  border-color: $grey500
  color: $grey700
  /.outer:hover &, &:hover
  /.outer:active &, &:active
    border-color: $red

    .icon
      fill: $white
      stroke: $white

  .icon
    fill: $grey700
    stroke: $grey700

.disabled
  &
  /.outer:hover &,
  &:hover
  &:active
    background: $grey700
    border-color: $grey700
    cursor: not-allowed
    color: #fff

.text
  line-height: 1.2
  display inline-block
  vertical-align: middle

.has_icon
  margin-left: 9px

.icon
  display inline-block
  vertical-align: middle
  fill: $dark-red
  stroke: $dark-red
  stroke-width: 0px

  .btn:hover &,
  .btn:active &
    fill: $white
    stroke: $white

.loading:after
  content: ''
  display: block
  position: absolute
  left: 50%
  top: 50%
  background: url(../../../styles/img/spin.svg)

  loader-sizes = '.sm' 16px, '.md' 20px, '.lg' 30px

  for loader-size in loader-sizes
    class = loader-size[0]
    size = loader-size[1]
    half-size = -(size / 2)

    /{class}&
      margin-top: half-size
      margin-left: half-size
      width: size
      height: size
