.btn {
  position: relative;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #2887e0;
  color: #2887e0;
  margin: 0;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  transition: all 0.1s ease-out;
  font-family: inherit;
  line-height: 1.1;
  -webkit-appearance: none;
}
.btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.outer {
  cursor: pointer;
}
.sm {
  padding: 6px 10px;
  font-size: 12px;
}
.md {
  padding: 8px 23px;
  font-size: 14px;
}
.lg {
  padding: 14px 50px;
  font-size: 16px;
}
.only_icon {
  padding: 0;
}
.only_icon.sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}
.only_icon.md {
  height: 35px;
  width: 35px;
  line-height: 33px;
}
.only_icon.lg {
  height: 49px;
  width: 49px;
  line-height: 47px;
}
.block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.bold {
  font-weight: bold;
}
.filled {
  background: #1c73c4;
  border-color: #1c73c4;
  color: #fff;
}
.ghost {
  border-color: #adadad;
  color: #717171;
}
.outer:hover .ghost,
.ghost:hover {
  border-color: #2887e0;
}
.disabled,
.outer:hover .disabled,
.disabled:hover {
  background: #717171;
  border-color: #717171;
  cursor: not-allowed;
  color: #fff;
}
.text {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}
.has_icon {
  margin-left: 9px;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  fill: #fff;
  stroke: #fff;
  stroke-width: 0px;
}
.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
}
.loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: url("../../../styles/img/spin.svg");
}
.sm .loading:after {
  margin-top: -8px;
  margin-left: -8px;
  width: 16px;
  height: 16px;
}
.md .loading:after {
  margin-top: -10px;
  margin-left: -10px;
  width: 20px;
  height: 20px;
}
.lg .loading:after {
  margin-top: -15px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
}
